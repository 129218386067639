.main-header-container {
    background: var(--appBlue);
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: calc(100% + 7rem);
    z-index: 5;
    opacity: .9;
}

.hamburger-options {
    display: none;
}

.links a{
    color: var(--white);
    font-weight: bold;
}
.links a.active{
    color: var(--darkGray);
}

.main-header-container .main-header-user-container {
}

.main-header-container .main-header-links-container {
}

.spacing {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 1.6rem;
}

/* styling for mobile */
@media (max-width: 761px) {

    .main-header-container {
        background: var(--appBlue);
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100%;
        z-index: 5;
        opacity: .9;
    }
    .links {
        display: none;
    }

    .hamburger-options {
        display: block !important;
        color: var(--darkGray);
        font-size: 2rem;
    }

    .sideNavOpen {
        margin-left: 7rem;
        transition: margin-left 2.1s;
        transition-delay: .3s;
    }
    .sideNavClose {
        /* width: 100%; */
        margin-left: 0rem;
        transition: margin-left 1.1s;
       
    }
}
