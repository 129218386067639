.base-page {
    height: 100vh;
    display: flex;
    position: relative;
    align-items: stretch;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}
.main-content .content-container {
    margin-top: 5.6rem;
    flex: 1;
}

.sideMenuOverlay{
    display: none;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .7;
    position: absolute;
    top:0;
    left:0;
}

.showOverlay{
    display: block;
}
