.contact-component{
    display: flex;
    justify-content: space-evenly;
    padding: 5rem;
}

.contact-component .section{
    width: 50%;
}

.contact-component .contact-component-left-side{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
}

.contact-card{
    width: 30rem;
    border: 1px solid silver;
    box-shadow: 2px 2px 2px silver;
    border-radius: 1.5rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
}
.contact-card .copied-message{
    position: absolute;
    top: .5rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: gray;
}
.contact-card .bottom{
    margin-bottom: 2rem;
}
.contact-card .logo{
    height: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-card .logo img{
    width: 10rem;
}
.contact-card .url{
    color: gray;
    font-weight: 600;
    height: 5rem;
    cursor: pointer;
    font-size: 1.3rem;
}
.contact-card .link a{
    color: var(--appBlue);
    font-size: 1.6rem;
}

.contact-component-right-side{
    display: inline-flex;
    justify-content: center;
    align-self: flex-start;  
}
.contact-component-right-side .required{
    color: var(--appBlue) 
}
.contact-component-right-side .form-container{
    border: 3px solid #F0F0F0;
    box-shadow: 2px 2px 2px #F0F0F0;
    border-radius: 1.8rem;
    padding: 1rem 2rem;
    width: 60%;
    position: relative;
    background-color: white;
}
.contact-component-right-side .form-container .form-row{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 2rem;
   gap: 2rem;
}
.contact-component-right-side .form-container .directions{
   margin-bottom: 2rem;
}
.contact-component-right-side .form-container h6{
    font-size: 2rem;
    margin: 2rem 0;
}
.contact-component-right-side .form-textarea{
    width: 100%;
}
.contact-component-right-side .label{
   margin-bottom: .5rem;
   font-size: 1.6rem;
   font-weight: bold;
}
.contact-component-right-side input{
   border-radius: .8rem;
   padding: 1rem 1.5rem;
   width: 70%;
   border:1px solid silver;
}
.contact-component-right-side input:focus{
    outline: none !important;
    border:1px solid var(--appBlue);
    box-shadow: 0 0 10px #719ECE;
}
.contact-component-right-side textarea{
   width: calc(100% - 3rem);
   min-height: 10rem;
   font-size: 1.6rem;
   resize: none;
   border-radius: .8rem;
   padding: 1rem 1.5rem;
   border:1px solid silver;
}
.contact-component-right-side .characters{
  margin: .5rem 0;
  text-align: right;
}
.contact-component-right-side textarea:focus{
    outline: none !important;
    border:1px solid var(--appBlue);
    box-shadow: 0 0 10px #719ECE;
}

.contact-component-right-side .form-container button{
    background: var(--appBlue);
    width: 20rem;
    color: #ffffff;
    padding: 1.2rem 1.8rem;
    border: none;
    border-radius: .8rem;
}
.contact-component-right-side .form-container button:hover{
    background: green;
}

@media (max-width: 761px) {
    .contact-component{
        flex-wrap: wrap;
        gap: 9rem;
        padding: 0rem;
    }
    .contact-component .section{
        width: 100%;
        justify-content: center;
    }

    .contact-component-right-side .form-container{
        width: 80%;
    }

    .contact-card{
        width: 70%;
    }

    .contact-component-right-side input{
        width: 10rem;
     }
    
}
@media (max-width: 761px) {
    .contact-component{
        margin: 3rem 0rem;
    }
    
}
