.side-nav-container {
    background: var(--white);
    height: 100vh;
    margin-left: -14.2rem;
    padding: 0 2rem;
    transition: margin-left 2s;
    /* transition-delay: .1s; */
    /* display: none; */
}
.side-nav-container .side-nav-main-item {
    display: flex;
    justify-content: center;
    width: 3rem;
    margin-bottom: 3rem;
}
.side-nav-container .side-nav-main-item a {
    color: var(--appBlue);
    font-size: 2rem;
}

.showSideMenu{
    /* display: block; */
    margin-left: 0rem;
    transition: margin-left 2s;
}

@media (max-width: 761px) {
    .side-nav-container {
        position: fixed;
        box-shadow: .5rem 0 .5rem -2rem #888;
        z-index: 10;
        top:0;
        left:0;
    }
}
@media (min-width: 762px) {
    .side-nav-container {
        display: none;
    }
}
