html {
    font-size: 10px;
}
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
span {
    margin: 0;
    padding: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: #000000;
}

a:visited {
    color: #000000;
}

li {
    list-style: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
